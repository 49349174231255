

export const loginUserReducer = ( state={currentUser:[]}, action)=>{
    switch(action.type){
        case 'USER_LOGIN_REQUEST' : return {
            loading :true
        }
        case 'USER_LOGIN_SUCCESS' : return {
            loading :false,
            success:true,
            currentUser:action.payload 
        }
        case 'USER_LOGIN_FAILED' : return {
            loading :false,
            error:action.payload
        }
        case 'USER_LOGOUT' : return {          
            currentUser:action.payload 
        }
        default : return state 
    }
}
