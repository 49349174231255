import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField,Button } from '@material-ui/core';
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import {getAllOrders, addOrder, deleteOrder, allOrdersClear, getAllOrdersLength, searchOrder, updateOrder, filterOrder, filterdc} from '../../redux/actions/orderAction';
import { deleteItemQuantity } from "../../redux/actions/stocksAction";
import {getAllCompanyNames} from '../../redux/actions/customerAction';
import view from '../../resources/img/view.png';
import RadioComponent from "../../components/Radio";
import { CSVLink, CSVDownload } from "react-csv";
import OnLineErrors from '../../components/OnLineErrors';
import Select from 'react-select';
import { saveAs } from "file-saver";
import axios from 'axios';
import './pagestyle.css'
import download from '../../resources/img/download.png';

import {useDispatch, useSelector} from 'react-redux';
var datetime = new Date();
var tdy_date = (datetime.toISOString().slice(0,10)).split("-").reverse().join("-");  
var t_date = (datetime.toISOString().slice(0,10)).split("-").join("-");  

export default function ViewOrders() {

  const [id, setid] = useState("");
  const [item_name, setitem_name] = useState("");
  const [item_name_val, setitem_name_val] = useState("");
  const [itemlist, setitemlist] = useState([]); 

  const [units, setunits] = useState("");
  const [quantity, setquantity] = useState("");
  const [dc, setdc] = useState("");

  const [dc1, setdc1] = useState("");
  const [dc2, setdc2] = useState("");


  const [clientlist, setclientlist] = useState([]); 
  const [clnt_name, setclnt_name] = useState("");
  const [clnt_name_val, setclnt_name_val] = useState("");


 
  const [errors, seterrors] = useState([]);

  const [user, setuser] = useState("");
  const [search, setsearch] = useState(''); 
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [trigger, settrigger] = useState("#myModal");
  
  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: errors.units ==='*' ? "red" : "gray",         
    })  
  };

  const   headers = [
    { label: "Entry Date", key: "entry_date" },
    { label: "User", key: "user" },
    { label: "DC", key: "dc" },
    { label: "Item Name", key: "item_name" },
    { label: "Client Name", key: "client_name" },
    { label: "Units", key: "units" },
    { label: "Quantity", key: "quantity"},
    { label: "Status", key: "status"}           
 ]

  const orders_state = useSelector(state=>state.getAllOrderReducer)  
  const {orders} = orders_state;
  //console.log('!!!!!!!',orders)
  const userState = useSelector(state=>state.loginUserReducer)
  const {currentUser} = userState;

  const item_names_state = useSelector(state=>state.getAllItemNamesReducer)  
  const {item_names} = item_names_state;

  const cnState = useSelector(state=>state.getAllCompanyNamesReducer)
  const customer = cnState.company_names;

  useEffect(() => {     
    if(currentUser.length>0){             
      var decoded = jwt_decode(userState.currentUser); 
       setuser(decoded.username)          
        if(decoded.role !== 'admin')
         { 
          window.location.href="/"
         }
        }
       else { window.location.href="/" }
       
     
       dispatch(allOrdersClear())
       dispatch(getAllOrdersLength(decoded.username)).then((response)=>{
        setTot(response.response.data.results[0].tot)
       })              
       dispatch(getAllOrders(1,itemsPerPage, decoded.username))
       dispatch(getAllCompanyNames())
       handleClientList()
       handleItemList();
 
    },[])

    //console.log('!!!!--USER-!!!',user)

    useEffect(() => {                    
      setData(orders) 
      },[orders])

  // useEffect(() => {       
  //   dispatch(allOrdersClear())
  //   dispatch(getAllOrdersLength()).then((response)=>{
  //    setTot(response.response.data.results[0].tot)
  //   })              
  //   dispatch(getAllOrders(1,itemsPerPage))
  //   dispatch(getAllCompanyNames())
  //   handleClientList()
  // },[])

  
  const dispatch = useDispatch()

 const  handleClientList = () => { 
  setclientlist(customer)
 };

//console.log('@@@@@@', clientlist)
 let client_name  = clientlist.map(function (clnl) {
  return { value: clnl.client_name, label: clnl.client_name };
 
})

const handleClear = () =>{
  setid('');
  setitem_name('');
  setunits('');
  setdc('');
  setquantity('');
  setclnt_name('');
  setclnt_name_val('')
}

const handleSearch = (val) =>{ 
  setfrom_date(''); setto_date('');setdc1('');setdc2('');
  setsearch(val);
   console.log('1-1-1-1-1-1', user)
    dispatch(searchOrder(val,user)).then((res)=>{
      setData(res.response.data.results)
    }).catch(err=>{
      setsearch('')
      dispatch(getAllOrders(1,itemsPerPage,user))
    })

    if(search.length === 1){
      dispatch(getAllOrders(1,itemsPerPage, user))
     }
}

// ------------------------- EDIT START --------------------------- //


const handleModal = (e) => {
  console.log('!!==========test=========!!',e)
     settrigger("#myModal") 
     const dob = moment(e.dob).format("DD-MM-YYYY");
     const {id, item_name,units, quantity, in_out} = e;
     
     setid(id);
     setitem_name(item_name);
     setunits(units);
     setquantity(quantity);
   
     
};
const handleDelete = (sal) => {
  var option = window.confirm(`Are You Sure want to delete  ${sal.dc}`);
  if (option) {
          dispatch(deleteOrder(sal.id))
          .then(res=>{                  
            dispatch(deleteItemQuantity(sal)).then(res=>{                 
               dispatch(getAllOrders(currentPage,itemsPerPage,user))
               handleClear()
           })                           
          })
 }
};
const handleSearchdate = ()=>{   
 // console.log('@#@#@#',clnt_name,'GGGGGGG',item_name)
  if (from_date !== '' && to_date !=='' && clnt_name !== '' && item_name !== '') {
    setsearch('');setdc1(''); setdc2('');
    const data = {from_date, to_date, user, clnt_name,item_name}   
    dispatch(filterOrder(data)).then((res)=>{
      //console.log('------ RP ------',res.response.data.results)
      setData(res.response.data.results)
    }).catch(err=>{      
      dispatch(getAllOrders(1,itemsPerPage,user))
    })
  }           
  else {
    alert('Select Fields')
  }  
}  

const handleFilterDc = () => {
  if (dc1 !== '' && dc2 !=='') {
    setsearch('');
    setfrom_date(''); setto_date('')
    const data = {dc1, dc2, user}   
    dispatch(filterdc(data)).then((res)=>{
      console.log('------ RP ------',res.response.data.results)
      setData(res.response.data.results)
    }).catch(err=>{      
      dispatch(getAllOrders(1,itemsPerPage,user))
    })
  }           
  else {
    alert('Select dc')
  }  
}

const handleUpdateFromModal = () =>{ 
  // const item_data = {id,item_name, units, quantity, clnt_name, dc }
  // const errors = validate(item_data);
  // seterrors(errors)
  // if (Object.keys(errors).length === 0) {        
  //     dispatch(addOrder(item_data)).then(res=>{
  //       console.log('&&&&&&&&&&&&&&&',res.response.data.success)
  //       if(res.response.data.success){
  //         alert('Inserted Successfully');         
  //         handleClear();
  //       }
  //     })
  // }
}


const  handleItemList = () => {  
  setitemlist(item_names)
 };

//console.log('@@@@@@', itemlist)
 let itm_name  = itemlist.map(function (inl) {
  return { value: inl.item_name, label: inl.item_name };
 
})



// ------------------------- Pagination start ------------------------------ //
      const [currentPage, setcurrentPage] = useState(1);
      const [itemsPerPage, setitemsPerPage] = useState(5);
      const [tot, setTot] = useState(0);
      const [pageNumberLimit, setpageNumberLimit] = useState(5);
      const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
      const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
      const [data, setData] = useState([]);
      const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
        dispatch(getAllOrders((Number(event.target.id)),itemsPerPage,user))
      };
      const pages = [];
      for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
       pages.push(i);
      }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

     useEffect(() => {
      var decoded = jwt_decode(userState.currentUser); 
      dispatch(getAllOrders(currentPage,itemsPerPage,decoded.username));    
    },[currentPage,itemsPerPage])

      const handleNextbtn = () => {
      setcurrentPage(currentPage + 1);  
      if (currentPage + 1 > maxPageNumberLimit) {
        setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
      }
    };
    const handlePrevbtn = () => {
      setcurrentPage(currentPage - 1);  
      if ((currentPage - 1) % pageNumberLimit == 0) {
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
      pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
    }  
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
      pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
    }
    const renderPageNumbers = pages.map((number) => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        return (
          <li
            key={number}
            id={number}
            onClick={handleClick}
            className={currentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });

    const handleLoadMore = () => {
      setcurrentPage(1)
      setmaxPageNumberLimit(5);
      setminPageNumberLimit(0);
      setitemsPerPage(itemsPerPage + 5);
    
    };



    let sum = 0;
    for (let i = 0; i < data.length; i++) {    
      if(data[i].status!== 'deleted'){
      sum += Number(data[i].quantity);
      }
    }
    console.log("Sum is " + sum) 



// ------------------------ Pagination end ------------------------ //

const validate = (data) => {
  const errors={};       
    if (!data.item_name) {
      errors.item_name = "*"
    }
    if (!data.units) {
      errors.units = "*"
    }
    if (!data.quantity) {
      errors.quantity = "*"
    }
    if (!data.clnt_name) {
      errors.clnt_name = "*"
    }
    if (!data.dc) {
      errors.dc = "*"
    }
    
             
  return errors;
};

const option_pt = [
  { value: 'A', label: 'TYPE A' },
  { value: 'B', label: 'TYPE B' },
  { value: 'C', label: 'TYPE C' },
  { value: 'D', label: 'TYPE D' }
];

  return (
    <>
    <AdminNavbar />
    <Section>
    <div className='view__acc_container'>
    <div className="view__filterbox__container">
  <div className="view__filterdate__container">
              <div style={{marginTop:'-0.5rem'}}>               
                <DatePicker           
                    value={from_date !== 'Invalid date' ? from_date : ''}
                    onChange={(date)=>{ setfrom_date(moment(date).format("DD-MM-YYYY"))}}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="From (Bill Date)"
                    monthsShown={1}
                    showYearDropdown
                  /> 
                      
                  <div style={{marginTop:'0.5rem',width:'15rem' }}>
                          <Select
                              value={clnt_name_val}                            
                              onChange={(e)=>{setclnt_name_val(e);setclnt_name(e.value)}}
                              options={client_name}
                              placeholder={"Customers..."}
                          />                          
                          { errors.clnt_name && <OnLineErrors text={errors.clnt_name} />}
                          </div>          
              </div>                          
                
              <div style={{marginTop:'-0.5rem'}}>      
              <DatePicker           
                    value={to_date !== 'Invalid date' ? to_date : ''}
                    onChange={(date)=>{ setto_date(moment(date).format("DD-MM-YYYY"))}}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="To (Bill Date)"
                    monthsShown={1}
                    showYearDropdown
                  />  
                                           
                     <div style={{marginTop:'0.5rem',width:'20rem' }}>
                            <Select
                              value={item_name_val}                            
                              onChange={(e)=>{setitem_name_val(e);setitem_name(e.value)}}
                              options={itm_name}
                              placeholder={  "Item Names..."}
                              styles={customStyles}
                           />                        
                      </div>                       
                         
              </div>

              <div>
              <Button variant="contained" color="secondary"
              onClick={()=>{handleSearchdate()}}
              ><i className="fa fa-search"></i> </Button>         
             </div>

             <div style={{width:'5rem'}}>
              <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
                onChange={(e) => {setdc1(e.target.value)}}
                value={dc1}
                placeholder="dc from"
                style={{backgroundColor:'white'}}
              />
              </div>
              <div style={{width:'5rem'}}>
              <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
               onChange={(e) => {setdc2(e.target.value)}}
                value={dc2}
                placeholder="dc to"
                style={{backgroundColor:'white'}}
              />
              </div>
              <Button variant="contained" color="primary"
              onClick={handleFilterDc}
              ><i className="fa fa-search"></i> </Button>

          <div style={{marginTop:'-1.8rem'}}> 
                  <CSVLink 
                          // filename={`VP-${tdy_date}.csv`}      
                          filename={`total - ${sum}.csv`}                                 
                          target="_blank"
                          // data={data}  
                          data = {data.map(row => ({...row,entry_date: moment(row.entry_date).format("DD-MM-YYYY")}))}
                          headers={headers}                       
                          >
                          <img  type="button" src={download} style={{height:'2rem',textAlign:'center',marginTop:'2rem'}} />
                  </CSVLink>
             </div>

         
  </div>
 
  <div className="view__filtersearch__container">
              <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
                onChange={(e) => {handleSearch(e.target.value)}}
                value={search}
                placeholder="Search"
                style={{backgroundColor:'white'}}
              />
              </div>
</div>



    <h1>Order Details</h1>
            {/*  */}
        
             {/*  */}

    <div className="table-responsive">
        <table className="table table-bordered table-responsive-sm">
            <thead style={{backgroundColor:'#00b894',color:'#fff'}}>
                <tr>
                    <th>S.No</th>
                    <th>User</th>
                    <th>EntryDate</th>
                    <th>DC</th>  
                    <th>Item Name</th> 
                    <th>Units</th> 
                    <th>quantity ({sum})</th>
                    <th>CustomerName</th>
                    <th>status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
              {data.length > 0 ?
               data.map((reg, index) => {
                    return (
                        // <tr key={index} onClick={() => {handleModal(reg)}} data-toggle="modal" data-target={trigger}>
                        <tr key={index} style={{ backgroundColor:reg.status === 'deleted' ? '#ff7979' : ''}}>
                            {/* <td> {((props.cp-1)*5)+(index+1)} </td> */}
                            <td> {(index+1)} </td> 
                            <td>{reg.user}</td>
                            <td> {moment(reg.entry_date).format("DD-MM-YYYY")} </td>     
                            <td>{reg.dc}</td>                                
                            <td>
                              {reg.item_name} </td>                            
                            <td>{reg.units}</td>  
                            <td>{reg.quantity}</td>                          
                            <td>{reg.client_name}</td>
                            <td>{reg.status}</td>
                            <td style={{cursor:'pointer',textAlign:'center'}}>
                               <i className="fa fa-trash" onClick={()=>{handleDelete(reg)}}></i>
                            </td>
                        </tr>
                    );
                    })
                    :<tr><td>No Data</td></tr>
                  }
            </tbody>

        </table> 
    </div>
    </div>

    {
  search.length>0 || from_date.length > 0 ? 
  null
  :
  <>
    <div className="pageNumbers">    
         <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >   &lt;&lt;
          </button>
        
        {pageDecrementBtn} 
        {renderPageNumbers}       
        {pageIncrementBtn}
        <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >   &gt;&gt;
        </button>      
    </div> 
    <div style={{display:'flex',justifyContent:'center', margin:'20px 50px'}}>
     <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
    </div>  
  </>
}   
    </Section>




{/*  */}
<div>
          <div className="modal" id="myModal" style={{marginTop:'3rem',zIndex:'10000'}}>
            <div className="modal-dialog modal-lg" style={{width:'100%'}}>
              <div className="modal-content">
                <div className="modal-header" style={{backgroundColor:'#664c8d',color:'#fff'}}>
                 
                  <h4 className="modal-title" style={{color:"yellow", paddingLeft:'25%', marginTop:'0.5rem'}}>
                   {item_name} </h4>
                    <div style={{textAlign:'center', paddingLeft:'7%'}}>
                      {/* <img src={`${apiUrl}/uploads/${userdata.photo}`} style={{height:'4rem', width:'6rem'}} />                  */}
                 </div>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">

{/* ================================ ------Start ------ ==================================== */}  

<br />    
<div className="row">
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">ItemName</label></div>
                 <div className='col-md-6'>   
                       <div style={{marginTop:'-1rem'}}>
                        <TextField 
                            id="outlined-basic" name='name'
                            error={ errors.item_name ? true : false}   variant="outlined"  fullWidth={false}
                            onChange={(e) => {setitem_name(e.target.value)}}
                            value={item_name}
                          />  
                       </div>
                 </div>
</div>    


<br/>  
<div className="row">
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">Units</label></div>
                 <div className='col-md-6'>   
                       <div style={{marginTop:'0rem'}}>                     
                       <TextField className='txtfld' id="outlined-basic" name='father_or_spouse' error={ errors.units ? true : false} variant="outlined"  fullWidth={false}
                           onChange={(e) => {setunits(e.target.value)}}
                           value={units}
                       />
                       </div>
                 </div>

</div>  
<br/>  
<div className="row">
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">Quantity</label></div>
                 <div className='col-md-6'>   
                       <div style={{marginTop:'-0.5rem'}}>                     
                       <TextField className='txtfld' id="outlined-basic" name='education' error={ errors.quantity ? true : false} variant="outlined"  fullWidth={false}
                         onChange={(e) => {setquantity(e.target.value)}}
                         value={quantity}
                        />                    
                       </div>
                 </div>

</div>  
<br/>  
<div className="row">
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">Company</label></div>
                 <div className='col-md-6'>   
                          <div style={{ marginTop: "0rem", width:'60%' }}>
                          <Select
                              value={clnt_name_val}                            
                              onChange={(e)=>{setclnt_name_val(e);setclnt_name(e.value)}}
                              options={client_name}
                              placeholder={"Select..."}
                          />                          
                          { errors.clnt_name && <OnLineErrors text={errors.clnt_name} />}
                          </div>
                 </div>

</div> 
<br/>  
<div className="row">
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">DC</label></div>
                 <div className='col-md-6'>   
                       <div style={{marginTop:'-0.5rem'}}>                     
                       <TextField className='txtfld' id="outlined-basic" name='dm' error={ errors.dc ? true : false} variant="outlined"  fullWidth={false}
                         onChange={(e) => {setdc(e.target.value)}}
                         value={dc}
                        />                    
                       </div>
                 </div>

</div>  
<br/>  


{/* ================================ ------END ------ ==================================== */}


                </div>

                <div className="modal-footer">
                 
                  
                <div className="mr-auto" style={{display:'flex', cursor:'pointer'}} aria-hidden="true"
                      onClick={handleUpdateFromModal}>
                      <i className="fa fa-save" style={{fontSize:'2.2rem',color:'#009432', }}></i>
                      <p style={{color:'#009432',paddingLeft:'0.5rem', marginTop:'0.4rem'}}>Place Order</p>
                </div>
                     
                      
                <div className="mr" style={{display:'flex', cursor:'pointer'}} aria-hidden="true"
                     onClick={()=>handleDelete(id)}>
                      <i className="fa fa-trash" style={{fontSize:'2.2rem',color:'#eb4d4b', }}></i>
                      <p style={{color:'#ff7979',paddingLeft:'0.5rem', marginTop:'0.4rem'}}>Remove</p>
                </div>
                          
                </div>

              </div>
            </div>
          </div>
        </div>
{/*  */}


</>
  )


}






const Section = styled.section`
.view__acc_container{
  margin:100px 50px;
}

.view__filterbox__container{
      margin:20px auto;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      gap: 1rem;   
      padding:1rem;  
      background-color: #b8e994;
}
.view__filterdate__container{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;        
      flex:0.7;
}
.view__filtersearch__container{  
  flex:0.3;
  text-align:center;
}

@media screen and (min-width: 280px) and (max-width: 720px) {
    
    .view__filterbox__container{
      display: flex;
      flex-direction: column;
      }
    .view__filterdate__container{
      display: flex;
      flex-direction: column;
    }
  }

`;

