import axios from 'axios';
import { apiUrl } from "../../config";


export const addStocks = (acc)=>async dispatch=>{
console.log('ACTION------>',acc)
       dispatch({type:'ADD_STOCKS_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/stocks/create`,acc)
           console.log('+++ add',response)
           dispatch({type:'ADD_STOCKS_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
           dispatch({type:'ADD_STOCKS_FAILED', payload:error}) 
       }
   }



export const allStocksClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_STOCKS_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_STOCKS_FAILED', payload:error}) 
    }
}   


export const getAllStocksLength=()=>async dispatch=>{  
try{
    const response =await axios.get(`${apiUrl}/api/stocks/getallstocks_length`)      
    return{  response  }  
} catch(error){   
}
}

export const getAllStocks=(offset,row_count)=>async dispatch=>{
    dispatch({type:'GET_STOCKS_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/stocks/getAllStocks/${offset}/${row_count}`)    
 
    dispatch({type:'GET_STOCKS_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_STOCKS_FAILED', payload:error})
}
}


export const deleteStocks = (accid)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/stocks/delete`,{accid})    
        return{  response  }
      
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}



export const searchStocks = (val)=>async dispatch=>{
    const data={id:val}
    try {
        const response = await axios.post(`${apiUrl}/api/stocks/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}



export const getAllItemNames=()=>async dispatch=>{
    dispatch({type:'GET_INAME_REQUEST'})
try{
    const response =await axios.get(`${apiUrl}/api/stocks/getallitemnames`)  
    //console.log('##123456##',response.data.results)          
    dispatch({type:'GET_INAME_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_INAME_FAILED', payload:error})
}
}


export const updateItemQuantity = (quan)=>async dispatch=>{
    console.log('++++++======+++++++',quan)
   const data ={id:quan.id, quantity:quan.quantity, maxquantity:quan.maxquantity} 
    try {
        const response = await axios.post(`${apiUrl}/api/stocks/updateitemsquantity`,data)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}


export const deleteItemQuantity = (quan)=>async dispatch=>{
    console.log('++++++======+++++++',quan)
   const data ={id:quan.p_id, quantity:quan.quantity} 
    try {
        const response = await axios.post(`${apiUrl}/api/stocks/deleteitemsquantity`,data)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const getTotalStocks=()=>async dispatch=>{
    dispatch({type:'GET_TOTAL_STOCKS_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/stocks/gettotalstocks`)    
 
    dispatch({type:'GET_TOTAL_STOCKS_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_TOTAL_STOCKS_FAILED', payload:error})
}
}