import axios from 'axios';
import { apiUrl } from "../../config";


export const addCustomer = (acc)=>async dispatch=>{
console.log('ACTION------>',acc)
       dispatch({type:'ADD_CUSTOMER_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/customers/create`,acc)
           console.log('+++ add',response)
           dispatch({type:'ADD_CUSTOMER_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
           dispatch({type:'ADD_CUSTOMER_FAILED', payload:error}) 
       }
   }



export const allCustomersClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_CUSTOMER_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_CUSTOMER_FAILED', payload:error}) 
    }
}   


export const getAllCustomersLength=()=>async dispatch=>{  
try{
    const response =await axios.get(`${apiUrl}/api/customers/getAllCustomers_length`)      
    return{  response  }  
} catch(error){   
}
}

export const getAllCustomers=(offset,row_count)=>async dispatch=>{
    dispatch({type:'GET_CUSTOMER_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/customers/getAllCustomers/${offset}/${row_count}`)    
 
    dispatch({type:'GET_CUSTOMER_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_CUSTOMER_FAILED', payload:error})
}
}


export const deleteCustomer = (accid)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/customers/delete`,{accid})    
        return{  response  }
      
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}



export const searchCustomer = (val)=>async dispatch=>{
    const data={id:val}
    try {
        const response = await axios.post(`${apiUrl}/api/customers/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}



export const getAllCompanyNames=()=>async dispatch=>{
    dispatch({type:'GET_CNAME_REQUEST'})
try{
    const response =await axios.get(`${apiUrl}/api/customers/getallcompanynames`)  
    //console.log('##123456##',response.data.results)          
    dispatch({type:'GET_CNAME_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_CNAME_FAILED', payload:error})
}
}
