import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField,Button } from '@material-ui/core';
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import {getAllProducts, deleteProduct, allProductsClear, getAllProductsLength, searchProduct, updateProduct} from '../../redux/actions/productAction';
import view from '../../resources/img/view.png';
import RadioComponent from "../../components/Radio";
import OnLineErrors from '../../components/OnLineErrors';
import Select from 'react-select';
import { saveAs } from "file-saver";
import axios from 'axios';
import './pagestyle.css'

import {useDispatch, useSelector} from 'react-redux';
var datetime = new Date();
var tdy_date = (datetime.toISOString().slice(0,10)).split("-").reverse().join("-");  
var t_date = (datetime.toISOString().slice(0,10)).split("-").join("-");  

export default function ViewSeller(props) {

  return (
    <>
 
    <Section>
  <div className='view__acc_container'>
    <div className="view__filtersearch__container">
              <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
                onChange={(e) => {props.onHandleSearch(e.target.value)}}              
                placeholder="Search"
                style={{backgroundColor:'white'}}
              />
   </div> 

    <h1>Sellers Details</h1>
    <div className="table-responsive">
        <table className="table table-bordered table-responsive-sm" style={{width:'100%', alignItems:'center'}}>
            <thead style={{backgroundColor:'rgb(241, 196, 15)'}}>
                <tr>
                    <th>S.No</th>                  
                    <th>Seller Name</th>                
                    <th></th>
                </tr>
            </thead>
            <tbody>
              {props.items.length > 0 ?
               props.items.map((clnt, index) => {
                    return (
                        <tr  key={index}>
                            {/* <td> {((props.cp-1)*5)+(index+1)} </td> */}
                            <td> {(index+1)} </td>                
                            <td>
                              {clnt.seller_name} 
                            </td>                          
                                              
                            <td style={{cursor:'pointer',textAlign:'center'}}>
                               <i className="fa fa-trash" onClick={()=>{props.onHandleDelete(clnt)}}></i>
                            </td>
                        </tr>
                    );
                    })
                    :<tr><td>No Data</td></tr>
                  }
            </tbody>

        </table> 
    </div>
  

  </div>



  
   
    </Section>




</>
  )

}






const Section = styled.section`
.view__acc_container{
  margin:100px auto;
  max-width:650px;
}

.view__filterbox__container{
      margin:20px auto;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      gap: 1rem;   
      padding:1rem;  
      background-color: #F1E5AC;
}
.view__filterdate__container{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;        
      flex:0.7;
}
.view__filtersearch__container{  
  flex:0.3;
  text-align:center;
}

@media screen and (min-width: 280px) and (max-width: 720px) {
    
    .view__filterbox__container{
      display: flex;
      flex-direction: column;
      }
    .view__filterdate__container{
      display: flex;
      flex-direction: column;
    }
  }

`;

