import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField,Button } from '@material-ui/core';
import * as moment from "moment";
import Select from 'react-select';
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import {useDispatch, useSelector} from 'react-redux';
import jwt_decode from "jwt-decode";
import ViewStocks from "./ViewStocks";
import OnLineErrors from '../../components/OnLineErrors';
import {addStocks, deleteStocks, allStocksClear, getAllStocksLength, searchStocks, getAllItemNames, getAllStocks, getTotalStocks} from '../../redux/actions/stocksAction';
import {addCustomer,getAllCustomers, deleteCustomer, allCustomersClear, getAllCustomersLength, searchCustomer, getAllCompanyNames} from '../../redux/actions/customerAction';


export default function Stocks() {

      const [id, setid] = useState("");
      const [item_name, setitem_name] = useState("");
         
      const [search, setsearch] = useState(''); 
      const [user, setuser] = useState("");
      const [errors, seterrors] = useState([]);
      const [is_edit, setis_edit] = useState(false);


      const [totalStocks, setTotalStocks] = useState([]);
    
      const customStyles = {
        control: (base) => ({
          ...base,
          borderColor: errors.units ==='*' ? "red" : "gray",         
        })  
      };
   
      const stocks_state = useSelector(state=>state.getAllStocksReducer)  
      const {stocks} = stocks_state;

      const total_stocks_state = useSelector(state=>state.getTotalStocksReducer)  
      const {total_stocks} = total_stocks_state;

      console.log('!!!!TOTAL!!!',total_stocks)
      console.log('!!!!!!!',stocks)
      const userState = useSelector(state=>state.loginUserReducer)
      const {currentUser} = userState;
    


      useEffect(() => {   
        if(currentUser.length>0){             
          var decoded = jwt_decode(userState.currentUser); 
           setuser(decoded.username)          
            if(decoded.role !== 'admin')
             { 
              window.location.href="/"
             }
            }
           else { window.location.href="/" }

        dispatch(allStocksClear())   
        dispatch(getAllStocksLength()).then((response)=>{
          setTot(response.response.data.results[0].tot)
         })     
         dispatch(getTotalStocks())
         dispatch(getAllStocks(1,itemsPerPage))
      },[])

      useEffect(() => {                    
        setData(stocks) 
        },[stocks])

        useEffect(() => {                    
          setTotalStocks(total_stocks) 
          },[total_stocks])  
     
    const dispatch = useDispatch()
    const handleClear = ()=>{
      setis_edit(false)
      setitem_name('');     
    }

    const Submit = () =>{
      const item_data = {item_name}
      console.log('000000',item_data)
      const errors = validate(item_data);
      seterrors(errors)
      console.log('@@@@@',errors)
      if (Object.keys(errors).length === 0) {
        if(!is_edit){
          dispatch(addStocks(item_data))
          .then(res=>{
              dispatch(getAllStocks(1,itemsPerPage))
              dispatch(getAllItemNames())
             console.log('RESPONSE', res)      
             alert(res.response.data.msg)
             handleClear()
          })
        }else{
            
        }      
      }
      else{
      // console.log('@#@#@#@#',errors)
      }
   }   
  
// -------------------------------------------------

   const handleSearch = (val) =>{
    console.log('!!==========SEARCH=========!!',val)     
    setsearch(val);
     
      dispatch(searchStocks(val)).then((res)=>{
        setData(res.response.data.results)
      }).catch(err=>{
        setsearch('')
        dispatch(getAllStocks(1,itemsPerPage))
      })
  }

  const handleEdit = (e) => {
    console.log('!!==========test=========!!',e)     
       const dob = moment(e.dob).format("DD-MM-YYYY");
       const {id, client_name } = e;
       
       setis_edit(true)
       setid(id);
       setitem_name(client_name);
     
       window.scrollTo({
        top: 100,
        behavior: 'smooth',
      })
  };
 
  //  const handleDelete = (e) => {    
  //   console.log('####',e.id)
  //   var option = window.confirm(`Are You Sure want to delete ${e.id}`);
  //   if (option) {
  //           dispatch(deleteCustomer(e.id))
  //           .then(res=>{  
  //            dispatch(allCustomersClear())
  //            dispatch(getAllCustomers(1,20))
  //            dispatch(getAllCompanyNames())
  //           })
  //  }
  // };


// ------------------------- Pagination start ------------------------------ //
const [currentPage, setcurrentPage] = useState(1);
const [itemsPerPage, setitemsPerPage] = useState(5);
const [tot, setTot] = useState(0);

const [pageNumberLimit, setpageNumberLimit] = useState(5);
const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


const [data, setData] = useState([]);



const handleClick = (event) => {
  setcurrentPage(Number(event.target.id));
  dispatch(getAllStocks((Number(event.target.id)),itemsPerPage))
};

const pages = [];
for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
 pages.push(i);
}

//  console.log('####-- CP ---###',currentPage)
//  console.log('####-- Tot ---###',tot)
//  console.log('###-- pag len ---##',pages.length)

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

useEffect(() => {
dispatch(getAllStocks(currentPage,itemsPerPage));    
},[currentPage,itemsPerPage])


const handleNextbtn = () => {
setcurrentPage(currentPage + 1);  
if (currentPage + 1 > maxPageNumberLimit) {
  setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
  setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
}
};

const handlePrevbtn = () => {
setcurrentPage(currentPage - 1);  
if ((currentPage - 1) % pageNumberLimit == 0) {
  setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
  setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
}
};

let pageIncrementBtn = null;
if (pages.length > maxPageNumberLimit) {
pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
}

let pageDecrementBtn = null;
if (minPageNumberLimit >= 1) {
pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
}

const renderPageNumbers = pages.map((number) => {
if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
  return (
    <li
      key={number}
      id={number}
      onClick={handleClick}
      className={currentPage == number ? "active" : null}
    >
      {number}
    </li>
  );
} else {
  return null;
}
});

const handleLoadMore = () => {
setcurrentPage(1)
setmaxPageNumberLimit(5);
setminPageNumberLimit(0);
setitemsPerPage(itemsPerPage + 5);

};

// ------------------------ Pagination end ------------------------ //

 const validate = (data) => {
    const errors={};       
      if (!data.item_name) {
        errors.item_name = "*"
      }
       
    return errors;
  };

  return (
    <>
     <AdminNavbar />
     <Section>
    <>   
     <ul className="wrapper">     
             
     <li className="form-row">
       <label htmlFor="name">Item Name</label>
       <TextField className='txtfld' id="outlined-basic" name='name' error={ errors.item_name ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {setitem_name(e.target.value)}}
        value={item_name}
       />   
     </li>


          
     <br/>

     <li className="form-row">
     <Button variant="contained"
       style={{
          borderRadius: 35,
          backgroundColor: "#1289A7",
          color:"#ffd700",
          padding: "10px 20px",
          fontSize: "14px"
        }} 
        onClick={Submit}> Submit </Button>  

    
   
     </li>

   </ul>
   </>
 
     </Section>
     <ViewStocks 
       items={data}
       totalStocks={totalStocks}
       onHandleEdit={handleEdit}
      //  onHandleDelete={handleDelete}
       onHandleSearch={handleSearch}
     />
 {
  search.length>0 ? 
  null
  :
  <>
    <div className="pageNumbers">    
         <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >   &lt;&lt;
          </button>
        
        {pageDecrementBtn} 
        {renderPageNumbers}       
        {pageIncrementBtn}
        <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >   &gt;&gt;
        </button>      
    </div> 
    <div style={{display:'flex',justifyContent:'center', margin:'20px 50px'}}>
     <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
    </div>  
  </>
}    
     </>
  )

      }

      const Section = styled.section`
      margin:50px auto;
      max-width:650px !important;
       .wrapper {
          background-color: #ffffff;
          border:2px solid #ffbf00;
          list-style-type: none;
          padding: 30px 50px;
          border-radius: 10px;   
        }
      
         
        .form-row {
          display: flex;
          justify-content: flex-end;
          padding: 0.5rem;
      
        }
        .form-row > label {
          padding: .5em 1em .5em 0;
          flex: 1.5;
          color:#3B2774;
        }  
      
       
        .form-row .txtfld {
          flex: 2;
        }
      
      
        @media screen and (min-width: 280px) and (max-width: 720px) {
          .form-row{
            display: flex;
            flex-direction: column;
            }
        }
        @media screen and (min-width: 720px) and (max-width: 1080px) {
          .form-row{
            display: flex;
            flex-direction: column;
            }
        }
      
      `;