

export const getAddProductReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_PRODUCT_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_PRODUCT_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_PRODUCT_FAILED': return{
            loading:false,
            error:action.payload
        }
        
        case 'ALL_PRODUCT_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllProductReducer=(state={products:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_PRODUCT_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_PRODUCT_SUCCESS': return{
            loading:false,
            products:action.payload
        }
        case 'GET_PRODUCT_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}