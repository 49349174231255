import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Router from './Router.js';
import {Provider} from 'react-redux';
import store from './redux/store';

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <Router />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
