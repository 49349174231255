import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';

import Login from './pages/login/Login';
import Product from './pages/product/Product.js';
import Orders from './pages/product/Orders.js';
import ViewOrders from './pages/product/ViewOrders.js';
import Customer from './pages/customer/Customer.js';
import Seller from './pages/seller/Seller.js';
import Stocks from './pages/stocks/Stocks.js';
import { Redirect } from "react-router-dom";
import {BrowserRouter, Route} from 'react-router-dom'


export default function Router() {
  return (
    <div>  
          <BrowserRouter>  
            <Route exact path="/product"  component={Product} /> 
            <Route exact path="/orders"  component={Orders} /> 
            <Route exact path="/vieworders"  component={ViewOrders} /> 
            <Route exact path="/customer"  component={Customer} /> 
            <Route exact path="/seller"  component={Seller} /> 
            <Route exact path="/stocks"  component={Stocks} /> 
            <Route exact path="/"   component={Login} />
            {/* <Route render={() => <Redirect to={{ pathname: "/" }} />} /> */}
            
          </BrowserRouter>    
    </div>
  )
}
