

export const getAddSellerReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_SELLER_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_SELLER_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_SELLER_FAILED': return{
            loading:false,
            error:action.payload
        }
        
        case 'ALL_SELLER_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllSellerReducer=(state={seller:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_SELLER_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_SELLER_SUCCESS': return{
            loading:false,
            seller:action.payload
        }
        case 'GET_SELLER_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}

export const getAllSellerNamesReducer=(state={seller_names:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_SNAME_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_SNAME_SUCCESS': return{
            loading:false,
            seller_names:action.payload
        }
        case 'GET_SNAME_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}