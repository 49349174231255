

export const getAddStocksReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_STOCKS_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_STOCKS_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_STOCKS_FAILED': return{
            loading:false,
            error:action.payload
        }
        
        case 'ALL_STOCKS_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllStocksReducer=(state={stocks:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_STOCKS_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_STOCKS_SUCCESS': return{
            loading:false,
            stocks:action.payload
        }
        case 'GET_STOCKS_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}

export const getAllItemNamesReducer=(state={item_names:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_INAME_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_INAME_SUCCESS': return{
            loading:false,
            item_names:action.payload
        }
        case 'GET_INAME_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}


export const getTotalStocksReducer=(state={total_stocks:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_TOTAL_STOCKS_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_TOTAL_STOCKS_SUCCESS': return{
            loading:false,
            total_stocks:action.payload
        }
        case 'GET_TOTAL_STOCKS_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}
