import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField,Button } from '@material-ui/core';
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
//import {getAllStocks, allStocksClear, getAllStocksLength, searchProduct, updateItemQuantity} from '../../redux/actions/productAction';
import {addStocks, deleteStocks, allStocksClear, getAllStocksLength, searchStocks, getAllItemNames, getAllStocks,updateItemQuantity} from '../../redux/actions/stocksAction';
import {addOrder} from '../../redux/actions/orderAction';

import {getAllCompanyNames} from '../../redux/actions/customerAction';
import view from '../../resources/img/view.png';
import RadioComponent from "../../components/Radio";
import OnLineErrors from '../../components/OnLineErrors';
import Select from 'react-select';
import { saveAs } from "file-saver";
import axios from 'axios';
import './pagestyle.css'

import {useDispatch, useSelector} from 'react-redux';
var datetime = new Date();
var tdy_date = (datetime.toISOString().slice(0,10)).split("-").reverse().join("-");  
var t_date = (datetime.toISOString().slice(0,10)).split("-").join("-");  

export default function Orders() {

  const [id, setid] = useState("");
  const [entry_date, setentry_date] = useState("");
  const [item_name, setitem_name] = useState("");
  const [units, setunits] = useState("");
  const [quantity, setquantity] = useState("");
  const [maxquantity, setmaxquantity] = useState("");
  const [dc, setdc] = useState("");

  const [clientlist, setclientlist] = useState([]); 
  const [clnt_name, setclnt_name] = useState("");
  const [clnt_name_val, setclnt_name_val] = useState("");

  const [loading, setloading] = useState(false);
 
  const [errors, seterrors] = useState([]);

  const [user, setuser] = useState("");
  const [search, setsearch] = useState(''); 
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const [trigger, settrigger] = useState("");
  

  const products_state = useSelector(state=>state.getAllProductReducer)  
  const {products} = products_state;
   //console.log('!!!******!!!',products)

   const stocks_state = useSelector(state=>state.getAllStocksReducer)  
   const {stocks} = stocks_state;

  const userState = useSelector(state=>state.loginUserReducer)
  const {currentUser} = userState;

  const cnState = useSelector(state=>state.getAllCompanyNamesReducer)
  const customer = cnState.company_names;
  //console.log('!!!!CNAME!!!',customer)

  useEffect(() => {     
    if(currentUser.length>0){             
      var decoded = jwt_decode(userState.currentUser); 
       setuser(decoded.username)          
        if(decoded.role !== 'admin')
         { 
          window.location.href="/"
         }
        }
       else { window.location.href="/" }
       setuser(decoded.username)
       dispatch(allStocksClear())   
       dispatch(getAllStocksLength(decoded.username)).then((response)=>{
         setTot(response.response.data.results[0].tot)
        })   
       dispatch(getAllStocks(currentPage,itemsPerPage, decoded.username));        
       
       dispatch(getAllCompanyNames())
       handleClientList()
    
    },[])

  useEffect(() => {                    
    setData(stocks) 
    },[stocks])

  
  const dispatch = useDispatch()

 const  handleClientList = () => {  
  setclientlist(customer)
 };

//console.log('@@@@@@', clientlist)
 let client_name  = clientlist.map(function (clnl) {
  return { value: clnl.client_name, label: clnl.client_name };
 
})

const handleClear = () =>{
  setid('');
  setitem_name('');
  setunits('');
  setdc('');
  setquantity('');
  setmaxquantity('')
  setclnt_name('');
  setclnt_name_val('')
  setentry_date('');  
}

const onHandleSearch = (val) =>{
  setsearch(val);
   
    dispatch(searchStocks(val,user)).then((res)=>{
      console.log('#####------>>>',res.response.data.results)
      setData(res.response.data.results)
    }).catch(err=>{
      setsearch('')
      dispatch(getAllStocks(1,itemsPerPage,user))
    })
    console.log(search,'>>>>>------>>>',search.length)
    if(search.length === 1){
      dispatch(getAllStocks(1,itemsPerPage, user))
     }
}




// ------------------------- EDIT START --------------------------- //


const handleModal = (e) => {
  console.log('!!==========test=========!!',e)
     settrigger("#myModal") 
     const dob = moment(e.dob).format("DD-MM-YYYY");
     const {id, item_name,units, quantity, maxquantity} = e;
     
     setid(id);
     setitem_name(item_name);
     setunits(units);
     setquantity('');
     setmaxquantity(quantity)
   

};

const handleUpdateFromModal = () =>{ 
  const item_data = {id,user, entry_date, item_name, units, quantity, maxquantity, clnt_name, dc }
  const errors = validate(item_data);
  seterrors(errors)
  if (Object.keys(errors).length === 0) {        
      setloading(true);
      dispatch(addOrder(item_data)).then(res=>{
        // console.log('&&&&&&&&&&&&&&&',res.response.data.success)
        if(res.response.data.success){
          dispatch(updateItemQuantity(item_data)).then(res=>{                  
            dispatch(getAllStocks(1,10,user))
            handleClear()  
            setloading(false)
            alert('Inserted Successfully');  
            window.location.reload() 
         })
         // alert('Inserted Successfully');         
          //window.location.reload()
          //handleClear();
        }
       
      })
  }
}





// ------------------------- Pagination start ------------------------------ //
      const [currentPage, setcurrentPage] = useState(1);
      const [itemsPerPage, setitemsPerPage] = useState(5);
      const [tot, setTot] = useState(0);
      const [pageNumberLimit, setpageNumberLimit] = useState(5);
      const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
      const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
      const [data, setData] = useState([]);
      const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
        dispatch(getAllStocks((Number(event.target.id)),itemsPerPage,user))
      };
      const pages = [];
      for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
       pages.push(i);
      }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    useEffect(() => {
      var decoded = jwt_decode(userState.currentUser); 
      //console.log('@!@!@!@!@!@!@!', decoded.username )
      dispatch(getAllStocks(currentPage,itemsPerPage, decoded.username));    
    },[currentPage,itemsPerPage])
    
      const handleNextbtn = () => {
      setcurrentPage(currentPage + 1);  
      if (currentPage + 1 > maxPageNumberLimit) {
        setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
      }
    };
    const handlePrevbtn = () => {
      setcurrentPage(currentPage - 1);  
      if ((currentPage - 1) % pageNumberLimit == 0) {
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
      pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
    }  
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
      pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
    }
    const renderPageNumbers = pages.map((number) => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        return (
          <li
            key={number}
            id={number}
            onClick={handleClick}
            className={currentPage == number ? "active" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });

    const handleLoadMore = () => {
      setcurrentPage(1)
      setmaxPageNumberLimit(5);
      setminPageNumberLimit(0);
      setitemsPerPage(itemsPerPage + 5);
    
    };

// ------------------------ Pagination end ------------------------ //

const validate = (data) => {
    const errors={};     
    if (!data.entry_date) {
      errors.entry_date = "*"
    }  
    if (!data.item_name) {
      errors.item_name = "*"
    }
    if (!data.units) {
      errors.units = "*"
    }
    if (!data.quantity) {
      errors.quantity = "*"
    }
    if (!data.clnt_name) {
      errors.clnt_name = "*"
    }
    if (!data.dc) {
      errors.dc = "*"
    }
    
             
  return errors;
};

const option_pt = [
  { value: 'A', label: 'TYPE A' },
  { value: 'B', label: 'TYPE B' },
  { value: 'C', label: 'TYPE C' },
  { value: 'D', label: 'TYPE D' }
];

  return (
    <>
    <AdminNavbar />
    <Section>
    <div className='view__acc_container'>
 
    <div className="view__filtersearch__container">
              <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
                onChange={(e) => {onHandleSearch(e.target.value)}}
                value={search}
                placeholder="Search"
                style={{backgroundColor:'white'}}
              />
   </div>


    <h1>Item Details</h1>
    <div className="table-responsive">
        <table className="table table-bordered table-responsive-sm">
            <thead style={{backgroundColor:'#82589F',color:'#fff'}}>
                <tr>
                    <th>S.No</th>
                    <th>User</th>
                    <th>Item Name</th> 
                    <th>Units</th> 
                    <th>Quantity</th>  
                  
                </tr>
            </thead>
            <tbody>
              {data.length > 0 ?
               data.map((reg, index) => {
                    return (
                        <tr key={index} onClick={() => {handleModal(reg)}} data-toggle="modal" data-target={trigger}>
                            {/* <td> {((props.cp-1)*5)+(index+1)} </td> */}
                            <td> {(index+1)} </td>  
                            <td>{user}</td>                         
                            <td>
                              {reg.item_name} </td>                            
                            <td>{reg.units}</td>  
                            <td>{reg.quantity}</td>
                           
                        </tr>
                    );
                    })
                    :<tr><td>No Data</td></tr>
                  }
            </tbody>

        </table> 
    </div>
    </div>

    {
  search.length>0 || from_date.length > 0 ? 
  null
  :
  <>
    <div className="pageNumbers">    
         <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >   &lt;&lt;
          </button>
        
        {pageDecrementBtn} 
        {renderPageNumbers}       
        {pageIncrementBtn}
        <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >   &gt;&gt;
        </button>      
    </div> 
    <div style={{display:'flex',justifyContent:'center', margin:'20px 50px'}}>
     <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
    </div>  
  </>
}   
    </Section>




{/*  */}
<div>
          <div className="modal" id="myModal" style={{marginTop:'3rem',zIndex:'10000'}}>
            <div className="modal-dialog modal-lg" style={{width:'100%'}}>
              <div className="modal-content">
                <div className="modal-header" style={{backgroundColor:'#664c8d',color:'#fff'}}>
                 
                  <h4 className="modal-title" style={{color:"yellow", paddingLeft:'25%', marginTop:'0.5rem'}}>
                   {item_name} </h4>
                    <div style={{textAlign:'center', paddingLeft:'7%'}}>
                      {/* <img src={`${apiUrl}/uploads/${userdata.photo}`} style={{height:'4rem', width:'6rem'}} />                  */}
                 </div>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">

{/* ================================ ------Start ------ ==================================== */}  
<br />    
<div className="row">
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">Entry Date</label></div>
                 <div className='col-md-6'>   
                       <div style={{marginTop:'-1rem'}}>
                       <div className="customDatePickerWidth">
                        <DatePicker           
                            value={entry_date !== 'Invalid date' ? entry_date : ''}
                            onChange={(date)=>{ setentry_date(moment(date).format("DD-MM-YYYY"))}}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="dd-mm-yyyy"
                            monthsShown={1}
                            showYearDropdown
                          />
                          <br/>
                          { errors.entry_date && <OnLineErrors text={errors.entry_date} />}
                      </div> 
                       </div>
                 </div>
</div>   

<br />  
<div className="row">
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">DC</label></div>
                 <div className='col-md-6'>   
                       <div style={{marginTop:'-0.5rem'}}>                     
                       <TextField className='txtfld' id="outlined-basic" name='dm' error={ errors.dc ? true : false} variant="outlined"  fullWidth={false}
                         onChange={(e) => {setdc(e.target.value)}}
                         value={dc}
                        />                    
                       </div>
                 </div>

</div>  
<br/>  
<div className="row">
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">Company</label></div>
                 <div className='col-md-6'>   
                          <div style={{ marginTop: "0rem", width:'60%' }}>
                          <Select
                              value={clnt_name_val}                            
                              onChange={(e)=>{setclnt_name_val(e);setclnt_name(e.value)}}
                              options={client_name}
                              placeholder={"Search..."}
                          />                          
                          { errors.clnt_name && <OnLineErrors text={errors.clnt_name} />}
                          </div>
                 </div>

</div> 
<br /> 
<div className="row" style={{marginTop:'0.8rem'}}>
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">ItemName</label></div>
                 <div className='col-md-6'>   
                       <div style={{marginTop:'-1rem'}}>
                        <TextField 
                            id="outlined-basic" name='name'
                            error={ errors.item_name ? true : false}   variant="outlined"  fullWidth={false}
                            onChange={(e) => {setitem_name(e.target.value)}}
                            value={item_name}
                          />  
                       </div>
                 </div>
</div>    


<br/>  
<div className="row">
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">Units</label></div>
                 <div className='col-md-6'>   
                       <div style={{marginTop:'0rem'}}>                     
                       <TextField className='txtfld' id="outlined-basic" name='father_or_spouse' error={ errors.units ? true : false} variant="outlined"  fullWidth={false}
                           onChange={(e) => {setunits(e.target.value)}}
                           value={units}
                       />
                       </div>
                 </div>

</div>  
<br/>  
<div className="row" style={{marginTop:'0.5rem'}}>
                 <div className='col-md-3'></div>
                 <div className='col-md-3'> <label htmlFor="name">Quantity({maxquantity})</label></div>
                 <div className='col-md-6'>   
                       <div style={{marginTop:'-0.5rem'}}>                          
                       <TextField className='txtfld' id="outlined-basic" name='education' error={ errors.quantity ? true : false} variant="outlined"  fullWidth={false}
                         onChange={(e) => {setquantity(e.target.value)}}
                         value={quantity}
                        />                    
                       </div>
                 </div>

</div>  

<br/>  

<br/>  


{/* ================================ ------END ------ ==================================== */}


                </div>

                <div className="modal-footer">

            {loading === false ? 
                <div className="mr" style={{display:'flex', cursor:'pointer'}} aria-hidden="true"
                onClick={handleUpdateFromModal}>
                <i className="fa fa-save" style={{fontSize:'2.2rem',color:'#009432', }}></i>
                <p style={{color:'#009432',paddingLeft:'0.5rem', marginTop:'0.4rem'}}>Place Order</p>
            </div> : 
                '...'} 
                  
                
                          
                </div>

              </div>
            </div>
          </div>
        </div>
{/*  */}


</>
  )


}






const Section = styled.section`
.view__acc_container{
  margin:100px 50px;
}

.view__filterbox__container{
      margin:20px auto;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      gap: 1rem;   
      padding:1rem;  
      background-color: #F1E5AC;
}
.view__filterdate__container{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;        
      flex:0.7;
}
.view__filtersearch__container{  
  flex:0.3;
  text-align:center;
}

@media screen and (min-width: 280px) and (max-width: 720px) {
    
    .view__filterbox__container{
      display: flex;
      flex-direction: column;
      }
    .view__filterdate__container{
      display: flex;
      flex-direction: column;
    }
  }

`;

