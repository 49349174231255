import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as moment from "moment";
import { TextField,Button } from '@material-ui/core';
import DatePicker from "react-datepicker";
import { apiUrl } from "../../config";
import jwt_decode from "jwt-decode";
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import {getAllProducts, deleteProduct, allProductsClear, getAllProductsLength, searchProduct, updateProduct} from '../../redux/actions/productAction';
import view from '../../resources/img/view.png';
import RadioComponent from "../../components/Radio";
import OnLineErrors from '../../components/OnLineErrors';
import Select from 'react-select';
import { saveAs } from "file-saver";
import axios from 'axios';
import { CSVLink, CSVDownload } from "react-csv";
import './pagestyle.css'
import download from '../../resources/img/download.png';
import {useDispatch, useSelector} from 'react-redux';
import {handleExcel} from '../../redux/actions/productAction';

var datetime = new Date();
var tdy_date = (datetime.toISOString().slice(0,10)).split("-").reverse().join("-");  
var t_date = (datetime.toISOString().slice(0,10)).split("-").join("-");  

export default function ViewProduct(props) {

  const   headers = [                      
                      { label: "Entry Date", key: "entry_date"},
                      { label: "User", key: "user" },
                      { label: "Item Name", key: "item_name" },
                      { label: "Seller Name", key: "seller_name" },
                      { label: "Units", key: "units" },
                      { label: "Quantity", key: "quantity"},  
                   ]
 // const dispatch = useDispatch()

 let sum = 0;
 for (let i = 0; i < props.items.length; i++) {
   sum += Number(props.items[i].quantity);
 }
 console.log("Sum is " + sum) 



 const totfun = (row) =>{
  let tot = 0;
  for (let i = 0; i <row.length; i++) {
    tot += Number(row[i].quantity);
  }
  console.log("TOT is " + tot) 
} 

  return (
    <>
 
    <Section>
    <div className='view__acc_container'>
  
   <div className="view__filterbox__container">
  <div className="view__filterdate__container">
              <div style={{marginTop:'-0.5rem'}}>               
                <DatePicker           
                    value={props.from_date !== 'Invalid date' ? props.from_date : ''}
                    onChange={(date)=>{props.onHandleFromDate(date)}}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="From (Bill Date)"
                    monthsShown={1}
                    showYearDropdown
                  />   

                        <div style={{marginTop:'0.5rem',width:'15rem' }}>
                        <Select
                            value={props.sell_name_val}
                            onChange={(e)=>{props.onHandlefill_sell_name_val(e);props.onHandlefill_sell_name(e.value)}}                   
                            options={props.seller_name}
                            placeholder={'Sellers...'}         
                        />                               
                        </div>  

              </div>                          
                
              <div style={{marginTop:'-0.5rem'}}>      
              <DatePicker           
                    value={props.to_date !== 'Invalid date' ? props.to_date : ''}
                    onChange={(date)=>{props.onHandleToDate(date)}}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="To (Bill Date)"
                    monthsShown={1}
                    showYearDropdown
                  />     

      <div style={{marginTop:'0.5rem',width:'15rem' }}>
           
              <Select
                                  value={props.fill_item_name_val}                            
                                  onChange={(e)=>{props.onHandlefill_item_name_val(e);props.onHandlefill_item_name(e.value)}}
                                  options={props.itm_name}
                                  placeholder={ "Item Names..."}                               
                              />            
       </div>                  
              </div>

              <div>
              <Button variant="contained" color="secondary"
              onClick={()=>{props.onHandleSearchDate()}}
              ><i className="fa fa-search"></i> </Button>         
             </div>

             <div style={{marginTop:'-1.8rem'}}> 
                  <CSVLink 
                          // filename={`VP-${tdy_date}.csv`}  
                          filename={`total - ${sum}.csv`}                         
                          target="_blank"
                          //data={props.items}  
                          data = {props.items.map(row => ({...row,entry_date: moment(row.entry_date).format("DD-MM-YYYY")}))}
                          headers={headers}                       
                          >
                          <img  type="button" src={download} style={{height:'2rem',textAlign:'center',marginTop:'2rem'}} />
                  </CSVLink>
             </div>
  </div >
 
  <div className="view__filtersearch__container">
              <TextField className='txtfld' id="outlined-basic"   variant="outlined"  fullWidth={false}
                onChange={(e) => {props.onHandleSearch(e.target.value)}}
                value={props.search}
                placeholder="Search"
                style={{backgroundColor:'white'}}
              />
              </div>
</div>


    <h1>Product Details</h1>
    <div className="table-responsive">
        <table className="table table-bordered table-responsive-sm">
            <thead style={{backgroundColor:'rgb(241, 196, 15)'}}>
                <tr>
                    <th>S.No</th>
                    <th>User</th>
                    <th>Entry Date</th>
                    <th>Item Name</th> 
                    <th>Seller Name</th>
                    <th>Units</th> 
                    <th>Quantity ({sum})</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
              {props.items.length > 0 ?
               props.items.map((item, index) => {
                    return (
                        <tr  key={index}>
                            {/* <td> {((props.cp-1)*5)+(index+1)} </td> */}
                            <td> {(index+1)} </td>  
                            <td>{item.user}</td>
                            <td> {moment(item.entry_date).format("DD-MM-YYYY")} </td>                 
                            <td>
                              {item.item_name} </td> 
                              <td>
                              {item.seller_name} </td>                            
                            <td>{item.units}</td>  
                            <td>{item.quantity}</td>                                         
                            {/* <td style={{cursor:'pointer',textAlign:'center'}}>                                                       
                              <i className="fa fa-edit" onClick={()=>{props.onHandleEdit(item)}}></i>
                            </td>                          */}
                            <td style={{cursor:'pointer',textAlign:'center'}}>
                               <i className="fa fa-trash" onClick={()=>{props.onHandleDelete(item)}}></i>
                            </td>
                        </tr>
                    );
                    })
                    :<tr><td>No Data</td></tr>
                  }
            </tbody>

        </table> 
    </div>
    </div>

   
    </Section>




</>
  )

}






const Section = styled.section`
.view__acc_container{
  margin:100px 50px;
}

.view__filterbox__container{
      margin:20px auto;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      gap: 1rem;   
      padding:1rem;  
      background-color: #F1E5AC;
}
.view__filterdate__container{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;        
      flex:0.7;
}
.view__filtersearch__container{  
  flex:0.3;
  text-align:center;
}

@media screen and (min-width: 280px) and (max-width: 720px) {
    
    .view__filterbox__container{
      display: flex;
      flex-direction: column;
      }
    .view__filterdate__container{
      display: flex;
      flex-direction: column;
    }
  }

`;

