import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField,Button } from '@material-ui/core';
import * as moment from "moment";
import Select from 'react-select';
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import {useDispatch, useSelector} from 'react-redux';
import jwt_decode from "jwt-decode";
import ViewProduct from "./ViewProduct";
import OnLineErrors from '../../components/OnLineErrors';

import {addProduct,getAllProducts, deleteProduct, allProductsClear, getAllProductsLength, searchProduct, updateProduct, filterProduct, handleExcel} from '../../redux/actions/productAction';
import { getAllItemNames } from "../../redux/actions/stocksAction";
import {  getAllSellerNames } from "../../redux/actions/sellerAction";

export default function Register() {
      let today = new Date();

      const [id, setid] = useState("");
      const [entry_date, setentry_date] =  useState("");
      const [item_name, setitem_name] = useState("");
      const [item_name_val, setitem_name_val] = useState("");

      const [fill_item_name, setfill_item_name] = useState("");
      const [fill_item_name_val, setfill_item_name_val] = useState("");

      const [itemlist, setitemlist] = useState([]); 

      const [units, setunits] = useState("");    
      const [units_val, setunits_val] = useState("");
      const [quantity, setquantity] = useState("");
      
      // const [add_quantity, setadd_quantity] = useState(0);

      const [sellerlist, setsellerlist] = useState([]); 
      const [sell_name, setsell_name] = useState("");
      const [sell_name_val, setsell_name_val] = useState("");

      const [fill_sell_name, setfill_sell_name] = useState("");
      const [fill_sell_name_val, setfill_sell_name_val] = useState("");

      const [search, setsearch] = useState(''); 
      const [user, setuser] = useState("");

      const [from_date, setfrom_date] = useState("");
      const [to_date, setto_date] = useState("");
  
      const [errors, seterrors] = useState([]);
      const [is_edit, setis_edit] = useState(false);

      const [csvData, setcsvData] = useState([]);
     

      const options = [
        { value: 'PCS', label: 'PCS'},
        { value: 'KGS', label: 'KGS' },
        { value: 'BOX', label: 'BOX' },   
        { value: 'ROLL', label: 'ROLL' },      
      ];
      const customStyles = {
        control: (base) => ({
          ...base,
          borderColor: errors.units ==='*' ? "red" : "gray",         
        })  
      };
   
      const products_state = useSelector(state=>state.getAllProductReducer)  
      const {products} = products_state;
      //console.log('!!!!!!!',products)

      const seller_names_state = useSelector(state=>state.getAllSellerNamesReducer)  
      const {seller_names} = seller_names_state;
 //console.log('!!!!SELLER NAMES!!!!',seller_names)

      const item_names_state = useSelector(state=>state.getAllItemNamesReducer)  
      const {item_names} = item_names_state;
      //console.log('!!!!STOCKS!!!!',item_names)

      const userState = useSelector(state=>state.loginUserReducer)
      const {currentUser} = userState;
    


      useEffect(() => {   
        if(currentUser.length>0){             
          var decoded = jwt_decode(userState.currentUser); 
           setuser(decoded.username)          
            if(decoded.role !== 'admin')
             { 
              window.location.href="/"
             }
            }
           else { window.location.href="/" }

        dispatch(allProductsClear())   
        dispatch(getAllProductsLength(decoded.username)).then((response)=>{
          setTot(response.response.data.results[0].tot)
         })   
        dispatch(getAllProducts(currentPage,itemsPerPage, decoded.username));   
        dispatch(getAllItemNames())
        dispatch(getAllSellerNames())
        
        dispatch(handleExcel(decoded.username))
        .then(res=>{   
          //console.log('#$#$#$#$$#$ USER $#$#$#$#$#$#$', res.response.data.users)
          setcsvData(res.response.data.users)
         })

         handleSellerList();
         handleItemList();
      },[])

      //console.log('#$#$#$#$$#$ USER $#$#$#$#$#$#$', user)

      useEffect(() => {                    
        setData(products) 
        },[products])
     
    const dispatch = useDispatch()

    const  handleItemList = () => {  
      setitemlist(item_names)   
     };
    
    //console.log('@@@@@@', itemlist)
     let itm_name  = itemlist.map(function (inl) {
      return { value: inl.item_name, label: inl.item_name };
     
    })

    const  handleSellerList = () => {  
      setsellerlist(seller_names)
     };
    
    //console.log('@@@@@@', sellerlist)

     let seller_name  = sellerlist.map(function (sell) {
      return { value: sell.seller_name, label: sell.seller_name };     
    })

    const handleClear = ()=>{
      setis_edit(false)
      setitem_name('');
      setitem_name_val('')
      setunits('');
      setunits_val('');
      setquantity('');   
     // setadd_quantity(0);   
      setentry_date('');
      setsell_name('');
      setsell_name_val('');
    }

    const Submit = () =>{
      const item_data = {id,user,entry_date,item_name,sell_name, units, quantity }
      console.log('000000',item_data)
      const errors = validate(item_data);
      seterrors(errors)
      console.log('@@@@@ VALIDATION ERRORS',errors)
      if (Object.keys(errors).length === 0) {
        if(!is_edit){
          dispatch(addProduct(item_data))
          .then(res=>{
             dispatch(getAllProducts(currentPage,itemsPerPage, user))
             console.log('RESPONSE', res)      
             alert(res.response.data.msg)
             handleClear()
             dispatch(handleExcel(user)).then(res=>{setcsvData(res.response.data.users)})
          })
        }else{
          //   dispatch(updateProduct(item_data))  
          //  .then(res=>{
          //     setis_edit(false)
          //     handleClear()
          //     dispatch(handleExcel(user)).then(res=>{setcsvData(res.response.data.users)})
          //     dispatch(getAllProducts(currentPage,itemsPerPage,user))
          //     if(res.response.data.success){
          //       alert('Updated')
          //     }
          // })
        }      
      }
      else{
      // console.log('@#@#@#@#',errors)
      }
   }   
  
// -------------------------------------------------

   const handleSearch = (val) =>{
    console.log('!!==========SEARCH=========!!',val)     
    setsearch(val);
     //console.log('SEARCH LENGTH', search.length);
    
      dispatch(searchProduct(val, user)).then((res)=>{
        setData(res.response.data.results)   
         setcsvData(res.response.data.results)       
      }).catch(err=>{
        setsearch('')
        dispatch(getAllProducts(1,itemsPerPage, user))
      })

      if(search.length === 1){
        dispatch(getAllProducts(1,itemsPerPage, user))
       }
    
  }

  const handleEdit = (e) => {
    console.log('!!==========test=========!!',e)     
       const dob = moment(e.dob).format("DD-MM-YYYY");
       const {id, item_name,units, quantity, entry_date} = e;
       
       setis_edit(true)
       setid(id);
       setitem_name(item_name);
       setitem_name_val(item_name)
       setunits(units);
       setunits_val(units);
       // setquantity(quantity);
       //setentry_date(moment(entry_date).format("DD-MM-YYYY"));
       setentry_date(moment(today).format("DD-MM-YYYY"));

       window.scrollTo({
        top: 100,
        behavior: 'smooth',
      })
  };
 
   const handleDelete = (e) => {    
    console.log('####',e.id)
    var option = window.confirm(`Are You Sure want to delete ${e.item_name}`);
    if (option) {
            const data = {id:e.id, item_name:e.item_name, quantity:e.quantity}
            dispatch(deleteProduct(data))
            .then(res=>{  
             dispatch(allProductsClear())
             dispatch(getAllProducts(1,20,user))
             dispatch(handleExcel(user)).then(res=>{setcsvData(res.response.data.users)})
            })
   }
  };

  const handleSerachdate = ()=>{   
    if (from_date !== '' && to_date !==''  && fill_sell_name !== '' && fill_item_name !== '') {
      setsearch('');
      const data = {from_date, to_date, user, fill_sell_name, fill_item_name}   
      console.log('------ DDDDDDDDD ------',data)
      dispatch(filterProduct(data)).then((res)=>{
        console.log('------ RP ------',res.response.data.results)
        setData(res.response.data.results)
      }).catch(err=>{      
        dispatch(getAllProducts(1,itemsPerPage, user))
      })
    }           
    else {
      alert('Select date')
    }  
  }  
    


// ------------------------- Pagination start ------------------------------ //
const [currentPage, setcurrentPage] = useState(1);
const [itemsPerPage, setitemsPerPage] = useState(5);
const [tot, setTot] = useState(0);

const [pageNumberLimit, setpageNumberLimit] = useState(5);
const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


const [data, setData] = useState([]);



const handleClick = (event) => {
  setcurrentPage(Number(event.target.id));
  dispatch(getAllProducts((Number(event.target.id)),itemsPerPage, user))
};

const pages = [];
for (let i = 1; i <= Math.ceil(tot / itemsPerPage); i++) {
 pages.push(i);
}

//  console.log('####-- CP ---###',currentPage)
//  console.log('####-- Tot ---###',tot)
//  console.log('###-- pag len ---##',pages.length)

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

useEffect(() => {
  var decoded = jwt_decode(userState.currentUser); 
  console.log('@!@!@!@!@!@!@!', decoded.username )
  dispatch(getAllProducts(currentPage,itemsPerPage, decoded.username));    
},[currentPage,itemsPerPage])


const handleNextbtn = () => {
setcurrentPage(currentPage + 1);  
if (currentPage + 1 > maxPageNumberLimit) {
  setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
  setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
}
};

const handlePrevbtn = () => {
setcurrentPage(currentPage - 1);  
if ((currentPage - 1) % pageNumberLimit == 0) {
  setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
  setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
}
};

let pageIncrementBtn = null;
if (pages.length > maxPageNumberLimit) {
pageIncrementBtn = <li onClick={handleNextbtn}> &hellip;+ </li>;
}

let pageDecrementBtn = null;
if (minPageNumberLimit >= 1) {
pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip;- </li>;
}

const renderPageNumbers = pages.map((number) => {
if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
  return (
    <li
      key={number}
      id={number}
      onClick={handleClick}
      className={currentPage == number ? "active" : null}
    >
      {number}
    </li>
  );
} else {
  return null;
}
});

const handleLoadMore = () => {
setcurrentPage(1)
setmaxPageNumberLimit(5);
setminPageNumberLimit(0);
setitemsPerPage(itemsPerPage + 5);

};

// ------------------------ Pagination end ------------------------ //

 const validate = (data) => {
    const errors={};  
      if (!data.entry_date) {
        errors.entry_date = "*"
      }     
      if (!data.item_name) {
        errors.item_name = "*"
      }
      if (!data.units) {
        errors.units = "*"
      }
      // if (!data.quantity ) {
      //   errors.quantity = "*"
      // }
      // if (!data.add_quantity ) {
      //   errors.add_quantity = "*"
      // }


      if(is_edit === false){
          if (!data.quantity ) {
           errors.quantity = "*"
          }
      } else {
        // if (!data.add_quantity ) {
        //   errors.add_quantity = "*"
        // }
      }
     
               
    return errors;
  };

  return (
    <>
     <AdminNavbar />
     <Section>
    <>   
     <ul className="wrapper">     
             

    <li className="form-row">  
       <label htmlFor="name">Entry Date</label>    
            <div className="customDatePickerWidth">
              <DatePicker           
                  value={entry_date !== 'Invalid date' ? entry_date : ''}
                  onChange={(date)=>{ setentry_date(moment(date).format("DD-MM-YYYY"))}}                 
                  dateFormat="yyyy-MM-dd"
                  placeholderText="dd-mm-yyyy"
                  monthsShown={1}
                  showYearDropdown
                />
                <br/>
                { errors.entry_date && <OnLineErrors text={errors.entry_date} />}
            </div>                
        </li>

     {/* <li className="form-row">
       <label htmlFor="name">Item Name</label>
       <TextField className='txtfld' id="outlined-basic" name='name' error={ errors.item_name ? true : false}   variant="outlined"  fullWidth={false}
        onChange={(e) => {setitem_name(e.target.value)}}
        value={item_name}
       />   
     </li> */}

     <li className="form-row">
          <label htmlFor="name">Item Name</label>
                       <div style={{width:'55%' }}>
                          <Select
                              value={item_name_val}                            
                              onChange={(e)=>{setitem_name_val(e);setitem_name(e.value)}}
                              options={itm_name}
                              placeholder={ is_edit ? item_name : "Search..."}
                              styles={customStyles}
                          />                         
                          </div>                       
       </li>
     
     <li className="form-row">
       <label htmlFor="name">Units</label>
       <div style={{width:'55%'}}>
           <Select
             value={units_val}
             onChange={(e)=>{setunits_val(e);setunits(e.value)}}                                 
             options={options}
             placeholder={is_edit ? units : 'Select...'}
             styles={customStyles}
            />              
       </div>      
     </li>

    
     <li className="form-row">
       <label htmlFor="name">Quantity</label>
        <TextField className='txtfld' id="outlined-basic" type='number' name='profession' error={ errors.quantity ? true : false}  variant="outlined" fullWidth={false}
          onChange={(e) => {setquantity(e.target.value)}}
          value={quantity}
        />
     </li>

    
<li className="form-row">
       <label htmlFor="name">Seller</label>
       <div style={{width:'55%'}}>
           <Select
             value={sell_name_val}
             onChange={(e)=>{setsell_name_val(e);setsell_name(e.value)}}                   
             options={seller_name}
             placeholder={is_edit ? units : 'Select...'}
             styles={customStyles}
            />              
       </div>      
     </li>
{/* 
     {is_edit === false ? 
     <li className="form-row">
       <label htmlFor="name">Quantity</label>
        <TextField className='txtfld' id="outlined-basic" type='number' name='profession' error={ errors.quantity ? true : false}  variant="outlined" fullWidth={false}
          onChange={(e) => {setquantity(e.target.value)}}
          value={quantity}
        />
     </li>
   :
   <li className="form-row">
     <label htmlFor="name">Add Quantity</label>
      <TextField className='txtfld' id="outlined-basic" type='number' name='profession' error={ errors.add_quantity ? true : false}  variant="outlined" fullWidth={false}
        onChange={(e) => {setadd_quantity(e.target.value)}}
        value={add_quantity}
      />
  </li>
  } */}
    
          
     <br/>

     <li className="form-row">
     <Button variant="contained"
       style={{
          borderRadius: 35,
          backgroundColor: "#1289A7",
          color:"#ffd700",
          padding: "10px 20px",
          fontSize: "14px"
        }} 
        onClick={Submit}> {is_edit === false ? 'Submit': 'Update'} </Button>  

         
       
       {is_edit === false ? ''
          :
          <Button variant="contained"
          style={{
            borderRadius: 15,
            marginLeft:5,
            backgroundColor: "#ffd700",
            color:"#1289A7",
            padding: "10px 20px",
            fontSize: "14px"
          }} 
          onClick={handleClear}>Clear </Button> 
      }
     </li>

   </ul>
   </>
 
     </Section>
     <ViewProduct 
       items={data}
       from_date={from_date}
       to_date={to_date}
       onHandleEdit={handleEdit}
       onHandleDelete={handleDelete}
       onHandleSearch={handleSearch}
       onHandleFromDate={(date)=>{ setfrom_date(moment(date).format("DD-MM-YYYY"))}}
       onHandleToDate={(date)=>{ setto_date(moment(date).format("DD-MM-YYYY"))}}
       onHandleSearchDate={handleSerachdate}
       csvData={csvData}
       item_name={item_name}
       item_name_val={item_name_val}
       onHandlefill_item_name={(val)=>{ setfill_item_name(val)}}
       onHandlefill_item_name_val={(val)=>{ setfill_item_name_val(val)}}
       itm_name={itm_name}

       fill_sell_name={fill_sell_name}
       fill_sell_name_val={fill_sell_name_val}
       onHandlefill_sell_name={(val)=>{ setfill_sell_name(val)}}
       onHandlefill_sell_name_val={(val)=>{ setfill_sell_name_val(val)}}
       seller_name={seller_name}

     />
 {
  search.length>0 ? 
  null
  :
  <>
    <div className="pageNumbers">    
         <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >   &lt;&lt;
          </button>
        
        {pageDecrementBtn} 
        {renderPageNumbers}       
        {pageIncrementBtn}
        <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >   &gt;&gt;
        </button>      
    </div> 
    <div style={{display:'flex',justifyContent:'center', margin:'20px 50px'}}>
     <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
    </div>  
  </>
}    
     </>
  )

      }

      const Section = styled.section`
      margin:50px auto;
      max-width:650px !important;
       .wrapper {
          background-color: #ffffff;
          border:2px solid #ffbf00;
          list-style-type: none;
          padding: 30px 50px;
          border-radius: 10px;   
        }
      
         
        .form-row {
          display: flex;
          justify-content: flex-end;
          padding: 0.5rem;
      
        }
        .form-row > label {
          padding: .5em 1em .5em 0;
          flex: 1.5;
          color:#3B2774;
        }  
      
       
        .form-row .txtfld {
          flex: 2;
        }
      
      
        @media screen and (min-width: 280px) and (max-width: 720px) {
          .form-row{
            display: flex;
            flex-direction: column;
            }
        }
        @media screen and (min-width: 720px) and (max-width: 1080px) {
          .form-row{
            display: flex;
            flex-direction: column;
            }
        }
      
      `;