import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Button } from '@material-ui/core';
import {loginUser} from '../../redux/actions/userActions';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import './Login.css';


export default function Login() {

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");

  const loginstate = useSelector(state=>state.loginUserReducer);
  const {error, loading} = loginstate

  const dispatch = useDispatch()

  useEffect(()=>{
     
  },[])


  const login = ()=>{      
          const user = {             
              username,
              password
          }        
          dispatch(loginUser(user))      
  }

  return (
    <div style={{marginTop:'10rem', }}>
      <div className="row justify-content-center" >
       
        <div className="col-md-4 col-sm-10 mt-5 text-left shadow-lg p-3 mb-5 bg-white rounded" style={{ borderTop:'4px solid  #1289A7'}}>
        <div>
           {loading && (<Loading/>)}           
           {error && (<Error error="Invalid Credentials" />)}

          <h2 className="text-center m-2" style={{ fontSize: "35px", color:'#000' }}>
            Login
          </h2>
          <br/>
          <div>
           
           <div>
            <input type="text"
              required
              placeholder="Username"
              className="form-control"
              value={username}
              onChange={(e) => {setusername(e.target.value)}}             
              />
           </div>
           <br/>
           <div style={{marginTop:'1rem'}}>
            <input
              type="text"
              required
              placeholder="Password"
              type='password'
              className="form-control"
              value={password}
              onChange={(e) => {setpassword (e.target.value)}}
            />
            </div>
            <br/>
           
            <Button variant="contained"
             style={{
                borderRadius: 5,
                backgroundColor: "#1289A7",
                color:'rgb(255, 215, 0)',
                padding: "10px 20px",
                fontSize: "14px",              
             }} 
             onClick={login}> LOGIN </Button>  
             <br></br>
            
          </div>
          </div> </div>

      </div>
    </div>
  );
}
