

export const getAddCustomerReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_CUSTOMER_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_CUSTOMER_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_CUSTOMER_FAILED': return{
            loading:false,
            error:action.payload
        }
        
        case 'ALL_CUSTOMER_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllCustomerReducer=(state={customer:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_CUSTOMER_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_CUSTOMER_SUCCESS': return{
            loading:false,
            customer:action.payload
        }
        case 'GET_CUSTOMER_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}

export const getAllCompanyNamesReducer=(state={company_names:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_CNAME_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_CNAME_SUCCESS': return{
            loading:false,
            company_names:action.payload
        }
        case 'GET_CNAME_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}