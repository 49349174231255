import axios from 'axios';
import { apiUrl } from "../../config";


export const addSeller = (acc)=>async dispatch=>{
console.log('ACTION------>',acc)
       dispatch({type:'ADD_SELLER_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/sellers/create`,acc)
           console.log('+++ add',response)
           dispatch({type:'ADD_SELLER_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
           dispatch({type:'ADD_SELLER_FAILED', payload:error}) 
       }
   }



export const allSellersClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_SELLER_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_SELLER_FAILED', payload:error}) 
    }
}   


export const getAllSellersLength=()=>async dispatch=>{  
try{
    const response =await axios.get(`${apiUrl}/api/sellers/getAllSellers_length`)      
    return{  response  }  
} catch(error){   
}
}

export const getAllSellers=(offset,row_count)=>async dispatch=>{
    dispatch({type:'GET_SELLER_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/sellers/getAllSellers/${offset}/${row_count}`)    
 
    dispatch({type:'GET_SELLER_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_SELLER_FAILED', payload:error})
}
}



export const deleteSeller = (accid)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/sellers/delete`,{accid})    
        return{  response  }
      
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}



export const searchSeller = (val)=>async dispatch=>{
    const data={id:val}
    try {
        const response = await axios.post(`${apiUrl}/api/sellers/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}



export const getAllSellerNames=()=>async dispatch=>{
    dispatch({type:'GET_SNAME_REQUEST'})
try{
    const response =await axios.get(`${apiUrl}/api/sellers/getallsellernames`)  
    //console.log('##123456##',response.data.results)          
    dispatch({type:'GET_SNAME_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_SNAME_FAILED', payload:error})
}
}
