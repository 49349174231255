

export const getAddOrderReducer=(state={}, action)=>{

    switch(action.type)
    {
        case 'ADD_ORDER_REQUEST': return{
            loading:true,
            ...state
        }
        case 'ADD_ORDER_SUCCESS': return{
            loading:false,
            success:true
        }
        case 'ADD_ORDER_FAILED': return{
            loading:false,
            error:action.payload
        }
        
        case 'ALL_ORDER_CLEAR': return{
            loading:false,
            success:false
        }
        
        default : return state
    }
}

export const getAllOrderReducer=(state={orders:[]}, action)=>{

    switch(action.type)
    {
        case 'GET_ORDER_REQUEST': return{
            loading:true,
            ...state
        }
        case 'GET_ORDER_SUCCESS': return{
            loading:false,
            orders:action.payload
        }
        case 'GET_ORDER_FAILED': return{
            loading:false,
            error:action.payload
        }
        default : return state
    }
}