import axios from 'axios';
import { apiUrl } from "../../config";


export const addProduct = (acc)=>async dispatch=>{
console.log('ACTION------>',acc)
       dispatch({type:'ADD_PRODUCT_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/products/create`,acc)
          // console.log('+++ add',response)
           dispatch({type:'ADD_PRODUCT_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
           dispatch({type:'ADD_PRODUCT_FAILED', payload:error}) 
       }
   }



export const allProductsClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_PRODUCT_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_PRODUCT_FAILED', payload:error}) 
    }
}   


export const getAllProductsLength=(user)=>async dispatch=>{
  
try{
    const response =await axios.get(`${apiUrl}/api/products/getallproducts_length/${user}`)    
    //console.log('%%%111%%%',response.data.results[0].tot);
    return{  response  }  
} catch(error){   
}
}

export const getAllProducts=(offset,row_count,user)=>async dispatch=>{
    dispatch({type:'GET_PRODUCT_REQUEST'})
    //console.log('^^^^^^^^USER^^^^^^^^',user)
try{
    const response =await axios.get(`${apiUrl}/api/products/getallproducts/${offset}/${row_count}/${user}`)    
    //console.log('%%%%%%',response);
    dispatch({type:'GET_PRODUCT_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_PRODUCT_FAILED', payload:error})
}
}

export const updateProduct = (pur)=>async dispatch=>{
   
    try {
        const response = await axios.post(`${apiUrl}/api/products/update`,pur)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const updateProductQuantity = (quan)=>async dispatch=>{
    console.log('++++++======+++++++',quan)
   const data ={id:quan.id, quantity:quan.quantity, maxquantity:quan.maxquantity} 
    try {
        const response = await axios.post(`${apiUrl}/api/products/updateproductquantity`,data)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const deleteProduct = (data)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/products/delete`,data)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const deleteProductQuantity = (quan)=>async dispatch=>{
    console.log('++++++======+++++++',quan)
   const data ={id:quan.p_id, quantity:quan.quantity} 
    try {
        const response = await axios.post(`${apiUrl}/api/products/deleteproductquantity`,data)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}

export const searchProduct = (val,user)=>async dispatch=>{
    const data={id:val,user:user}
    try {
        const response = await axios.post(`${apiUrl}/api/products/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}

export const filterProduct = (data)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/products/filterdate`,data)          
        return{  response  }        
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}


export const handleExcel = (user)=>async dispatch=>{  
    try {
        const response = await axios.get(`${apiUrl}/api/products/excel/${user}`)  
        //console.log('@@@@@@@',response)  
        return{  response  }
      
    } catch (error) {
        alert('Something Went Wrong !!')
        console.log(error)
    }
}


// export const updateItemQuantity = (quan)=>async dispatch=>{
//     console.log('++++++======+++++++',quan)
//    const data ={id:quan.id, quantity:quan.quantity, maxquantity:quan.maxquantity} 
//     try {
//         const response = await axios.post(`${apiUrl}/api/products/updateitemsquantity`,data)    
//         return{  response  }
//        // window.location.reload()
//     } catch (error) {
//         alert('Something Went Wrong')
//         console.log(error)
//     }
// }

// export const deleteItemQuantity = (quan)=>async dispatch=>{
//     console.log('++++++======+++++++',quan)
//    const data ={id:quan.p_id, quantity:quan.quantity} 
//     try {
//         const response = await axios.post(`${apiUrl}/api/products/deleteitemsquantity`,data)    
//         return{  response  }
//        // window.location.reload()
//     } catch (error) {
//         alert('Something Went Wrong')
//         console.log(error)
//     }
// }



 