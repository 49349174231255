import axios from 'axios';
import { apiUrl } from "../../config";


export const addOrder = (acc)=>async dispatch=>{
console.log('ACTION------>',acc)
           dispatch({type:'ADD_ORDER_REQUEST'})
       try {
           const response = await axios.post(`${apiUrl}/api/Orders/create`,acc)
           console.log('+++ add',response)
           dispatch({type:'ADD_ORDER_SUCCESS'})
           return{
               response
           }
          
       } catch (error) {
           dispatch({type:'ADD_ORDER_FAILED', payload:error}) 
       }
   }



export const allOrdersClear = (acc)=>async dispatch=>{
    try {
      
        dispatch({type:'ALL_ORDER_CLEAR'})
    } catch (error) {
        dispatch({type:'ALL_ORDER_FAILED', payload:error}) 
    }
}   


export const getAllOrdersLength=(user)=>async dispatch=>{
  
try{
    const response =await axios.get(`${apiUrl}/api/Orders/getallOrders_length/${user}`)    
    //console.log('%%%111%%%',response.data.results[0].tot);
    return{  response  }  
} catch(error){   
}
}

export const getAllOrders=(offset,row_count,user)=>async dispatch=>{
    dispatch({type:'GET_ORDER_REQUEST'})

try{
    const response =await axios.get(`${apiUrl}/api/Orders/getallOrders/${offset}/${row_count}/${user}`)    
   // console.log('%%%%%%',response);
    dispatch({type:'GET_ORDER_SUCCESS',payload:response.data.results})
} catch(error){
    dispatch({type:'GET_ORDER_FAILED', payload:error})
}
}

export const updateOrder = (pur)=>async dispatch=>{
   
    try {
        const response = await axios.post(`${apiUrl}/api/Orders/update`,pur)    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}



export const deleteOrder = (accid)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/Orders/delete`,{accid})    
        return{  response  }
       // window.location.reload()
    } catch (error) {
        alert('Something Went Wrong')
        console.log(error)
    }
}



export const searchOrder = (val,user)=>async dispatch=>{
    const data={id:val,user:user}
    console.log('DATA',data)
    try {
        const response = await axios.post(`${apiUrl}/api/Orders/search`,data)          
        return{  response  }
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}

export const filterOrder = (data)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/Orders/filterdate`,data)          
        return{  response  }        
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}

export const filterdc = (data)=>async dispatch=>{
    try {
        const response = await axios.post(`${apiUrl}/api/Orders/filterdc`,data)          
        return{  response  }        
       // window.location.reload()
    } catch (error) {
        return{  error  }
        //alert('Something Went Wrong')
        console.log(error)
    }
}



// export const updateOrderQuantity = (quan)=>async dispatch=>{
//     console.log('++++++======+++++++',quan)
//    const data ={id:quan.id, quantity:quan.quantity, maxquantity:quan.maxquantity} 
//     try {
//         const response = await axios.post(`${apiUrl}/api/Orders/updateorderquantity`,data)    
//         return{  response  }
//        // window.location.reload()
//     } catch (error) {
//         alert('Something Went Wrong')
//         console.log(error)
//     }
// }


// export const deleteOrderQuantity = (quan)=>async dispatch=>{
//     console.log('++++++======+++++++',quan)
//    const data ={id:quan.p_id, quantity:quan.quantity} 
//     try {
//         const response = await axios.post(`${apiUrl}/api/Orders/deleteorderquantity`,data)    
//         return{  response  }
//        // window.location.reload()
//     } catch (error) {
//         alert('Something Went Wrong')
//         console.log(error)
//     }
// }