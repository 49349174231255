import { combineReducers } from "redux";
//import { createStore, applyMiddleware } from "redux";
import { configureStore } from '@reduxjs/toolkit'
//import thunk from "redux-thunk";
//import {composeWithDevTools} from 'redux-devtools-extension'

import {loginUserReducer} from './reducers/userReducers'
import {getAddProductReducer, getAllProductReducer} from './reducers/productReducers';
import {getAddOrderReducer, getAllOrderReducer} from './reducers/orderReducers';
import {getAddCustomerReducer,getAllCustomerReducer, getAllCompanyNamesReducer} from './reducers/customerReducers';
import {getAddSellerReducer, getAllSellerReducer, getAllSellerNamesReducer} from './reducers/sellerReducers';
import {getAddStocksReducer, getAllStocksReducer, getAllItemNamesReducer, getTotalStocksReducer} from './reducers/stocksReducers';
import { persistReducer, PERSIST} from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler:autoMergeLevel2,
    transforms: [
      encryptTransform({
        secretKey: 'my-research-super-secret-key',
        onError: function (error) {
          // Handle the error.
        },
      }),
    ],
  }
   
 
const finalReducer = combineReducers({    
    loginUserReducer:loginUserReducer,  
    getAddProductReducer:getAddProductReducer,
    getAllProductReducer:getAllProductReducer,
    getAddCustomerReducer:getAddCustomerReducer,
    getAllCustomerReducer:getAllCustomerReducer,
    getAllCompanyNamesReducer:getAllCompanyNamesReducer,
    getAddOrderReducer:getAddOrderReducer,
    getAllOrderReducer:getAllOrderReducer,
    getAddStocksReducer:getAddStocksReducer,
    getAllStocksReducer:getAllStocksReducer,
    getAllItemNamesReducer:getAllItemNamesReducer,
    getAddSellerReducer:getAddSellerReducer,
    getAllSellerReducer:getAllSellerReducer,
    getAllSellerNamesReducer:getAllSellerNamesReducer,
    getTotalStocksReducer:getTotalStocksReducer
});


const persistedReducer = persistReducer(persistConfig, finalReducer) 

//const composeEnhancers = composeWithDevTools({})
//const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

const store = configureStore({ 
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [ PERSIST ],
    },
  }),
 })

export default store;