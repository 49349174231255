
import React,{useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser} from '../../redux/actions/userActions';
import jwt_decode from "jwt-decode";
import {useHistory,Link,useLocation,NavLink} from "react-router-dom"; 
import logo from '../../assets/nk.webp';
import './AdminNavbar.css';

const AdminNavbar = () => {
    const userState = useSelector(state=>state.loginUserReducer)
    const {currentUser} = userState;

    const [navLinkOpen, navLinkToggle] =  useState(false);
    const history = useHistory();

    var decoded = jwt_decode(currentUser); 

    const handleNavlinkToggle = () =>{
        navLinkToggle(!navLinkOpen)
    }

    const dispatch = useDispatch()
    const logOut = ()=>{    
        dispatch(logoutUser()) .then(()=>{           
            history.push("/login");
        })     
     }


    const renderClasses = () =>{
        let classes = "navlinks";
        if(navLinkOpen){
            classes += " active"
        }
        return classes;
    }
    return ( 
        <div className='navbar' id='top'>
            <nav>
                <div className='logo'>                    
                      <h4>{decoded.username}</h4>              
                </div>
                <ul className={renderClasses()}>
                    {/* <li className='link' href="#"><a>Home</a></li> */}
                    <NavLink onClick={handleNavlinkToggle} to="/stocks" smooth="true"  duration={500} exact activeClassName="click"> <h4>Stocks</h4> </NavLink>
                    <NavLink onClick={handleNavlinkToggle} to="/product" smooth="true"  duration={500} exact activeClassName="click"> <h4>Add Items</h4> </NavLink>
                    <NavLink onClick={handleNavlinkToggle} to="/orders" smooth="true"  duration={500} exact activeClassName="click"> <h4>Add Orders</h4> </NavLink>
                    <NavLink onClick={handleNavlinkToggle} to="/vieworders" smooth="true"  duration={500} exact activeClassName="click"> <h4>View Orders</h4> </NavLink>
                    
                    <NavLink onClick={handleNavlinkToggle} to="/customer" smooth="true"  duration={500} exact activeClassName="click"> <h4>Add Customer</h4> </NavLink>
                    <NavLink onClick={handleNavlinkToggle} to="/seller" smooth="true"  duration={500} exact activeClassName="click"> <h4>Add Seller</h4> </NavLink>
                   
                    <NavLink onClick={logOut} to="/#"  smooth="true" duration={500}> <h4>LogOut</h4> </NavLink>
                   
                </ul>
                <div onClick={handleNavlinkToggle} className='hamburger-toggle'>
                    <i className='fas fa-bars fa-lg'></i>
                </div>
             
            </nav>
        </div>
     );
}
 
export default AdminNavbar;